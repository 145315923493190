import { sendGAEvent } from '@common/utlis';

import type { LayoutProps } from '../components/layout';
import type { NextPage } from 'next';
import type { AppProps, NextWebVitalsMetric } from 'next/app';
import type { ReactElement, ReactNode } from 'react';

export type NextPageWithLayout<P = LayoutProps, IP = P> = NextPage<P, IP> & {
  getLayout?: (page: ReactElement<P>, pageProps: P) => ReactNode;
};

type AppPropsWithLayout = AppProps & {
  Component: NextPageWithLayout;
};

export function reportWebVitals({ name, value }: NextWebVitalsMetric) {
  const sendMetric = () => {
    const sendGAEventWithParams = () => {
      return sendGAEvent({
        category: 'web-vitals',
        action: name,
        additionalTrackingParameters: { 'event-value': value.toString() }
      });
    };
    if (!sendGAEventWithParams()) {
      window.setTimeout(sendMetric, 500);
    }
  };
  sendMetric();
}

export default function MyApp({ Component, pageProps }: AppPropsWithLayout) {
  // Use the layout defined at the page level, if available
  const getLayout = Component.getLayout || ((page) => page);
  return getLayout(<Component {...pageProps} />, pageProps);
}
